import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"

const PrivateRoute = ({ component: Component, location, language, ...rest }) => {


  if (!isLoggedIn() && location.pathname !== `/app/log_in`) {
    if (language === "en")
      {
        navigate("/enApp/log_in")
      }
      
    else (
      navigate("/app/log_in")
    )
    return null
  }
  return (
    <Component language={language} {...rest} />

  )

}

export default PrivateRoute