
import { graphql, useStaticQuery } from "gatsby"



export default  () => {
    const data = useStaticQuery(graphql`
    query offersDataQuery{
        allWordpressWpOffers {
            edges {
                node {
                    wordpress_id
                    title
                    slug
                    id
                    acf {
                    discount_percentage
                    offer_group
                    offer_location
                    description_in_english
                    description_in_icelandic
                    website
                    }
                }
            }
        }
    }
  `)
  return data
}
