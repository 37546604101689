import React from "react"
import { getUser, logout } from "../services/auth"
import Offers from "./offers"
import SEO from "./seo"
import { Link } from "gatsby"

const Profile = ({ language }) => (
  <React.Fragment>
    <div className="profile-container">
      <SEO
        title={language === "en" ? "Welcome " : "Velkomi/n " + getUser().username}
      />
      <div className="title-container">
        <h1>
          {" "}
          {language === "en"
            ? "Welcome " + getUser().username
            : "Velkomin/n " + getUser().username}{" "}
        </h1>
        <h2>{language === "en" ? "your discounts" : "Afslættirnir þínir"}</h2>
      </div>
      <div className="offers">
        <Offers group={getUser().role} language={language} />
      </div>

      <div className="settings">
        <h2>{language === "en" ? "Settings" : "Stillingar"}</h2>
        <span>
          <Link
            to={
              language === "en"
                ? "/enApp/change_password"
                : "/app/nytt_lykilord"
            }
          >
            <button>
              {" "}
              {language === "en" ? "Change Password" : "Breyta Lykilorði"}{" "}
            </button>
          </Link>
          <button>
            {" "}
            {language === "en"
              ? "Sign up for email list"
              : "Skrá mig á póstlista"}{" "}
          </button>
          <button onClick={() => logout(language)}>
            {language === "en" ? "logout" : "Skrá mig út"}
          </button>
        </span>
      </div>
    </div>
  </React.Fragment>
)

export default Profile
