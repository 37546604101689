import React from "react"
import SEO from "../components/seo"
import { handleLogin, isLoggedIn } from "../services/auth"
import { navigate } from "@reach/router"
class Login extends React.Component {
  //(this.props.location.pathname !== `/app/profile` || this.props.location.pathname !== `/enApp/profile` )
  state = {
    username: ``,
    password: ``,
    
  }
  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    handleLogin(this.state, this.props.language)
  }
  render() {
    if (isLoggedIn()) {
      if (this.props.language === "en") navigate("/enApp/profile")
      else navigate("/app/profile")
      return null
    }

    return (
      <React.Fragment>
        <div className="login-container">
          <SEO title="Innskráningarsíða" />
          <div className="login-header">
            <h1>{this.props.language === "en" ? "Login" : "Innskráning"}</h1>
            <h2>
              {this.props.language === "en"
                ? "Drivers/Organizations"
                : "Bifreiðastjóri/Bifreiðafélög"}
            </h2>
            <p>
              {this.props.language === "en"
                ? "with membership"
                : "með félagsaðild"}
            </p>
          </div>
          <form
            className="login-form"
            method="post"
            onSubmit={event => {
              this.handleSubmit(event)
            }}
          >
            <label>
              <input
                className="form-control"
                type="text"
                name="username"
                placeholder={
                  this.props.language === "en" ? "Username" : "Notendanafn"
                }
                onChange={this.handleUpdate}
              />
            </label>
            <label>
              <input
                className="form-control"
                type="password"
                name="password"
                placeholder={
                  this.props.language === "en" ? "Password" : "Lykilorð"
                }
                onChange={this.handleUpdate}
              />
            </label>
            <label>
            <p class="error-msg password-error"> 
              {this.props.language === "en" ? 
                "Incorrect password or username":
                "Rangt Lykilorð eða notandanafn"
              }
            </p>
            </label>
            <input
              className="login-btn"
              type="submit"
              value={this.props.language === "en" ? "Log in" : "Innskrá"}
            />
          </form>
        </div>
      </React.Fragment>
    )
  }
}
export default Login
