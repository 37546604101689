import React from "react"
import { handlePasswordChange } from "../services/auth"
import SEO from "../components/seo"

class PasswordChange extends React.Component {
    state = {
        password: ``,
        newPassword: ``,
        reNewPassword: ``,
    }

    handleUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }
    handleSubmit = event => {
        event.preventDefault()
        handlePasswordChange(this.state)
    }
    render() {
        return (
            <React.Fragment>
                <div className="login-container">
                    <SEO title="Breyta Lykilorði" />
                    <div className="login-header">
                        <h1>{this.props.language === "en" ? "Change Password" : "Breyta Lykilorði"}</h1>
                        <h2>
                        {this.props.language === "en"
                            ? "for taxi.is"
                            : "á taxi.is"}
                        </h2>
                        <p>
                        {this.props.language === "en"
                            ? "Please type in the required info and click confirm" 
                            : "Vinsamlegast sláið inn eftirfarandi og ýtið á staðfesta"}
                        </p>
                    </div>
                    <form
                        className="login-form"
                        method="post"
                        onSubmit={event => {
                            this.handleSubmit(event)
                        }}
                    >
                        <div>
                            <label>
                                <input className="form-control" 
                                    placeholder = {this.props.language === "en" ? "Old password" : "Gamalt lykilorð"}
                                    type="password"
                                    name="password"
                                    onChange={this.handleUpdate}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                
                                <input className="form-control"
                                    placeholder = {this.props.language === "en" ? "New password" : "Nýtt lykilorð"}
                                    type="password"
                                    name="newPassword"
                                    onChange={this.handleUpdate}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                
                                <input className="form-control"
                                    placeholder = {this.props.language === "en" ? "Retype new password" : "Nýtt lyklorð aftur"}
                                    type="password"
                                    name="reNewPassword"
                                    onChange={this.handleUpdate}
                                />
                            </label>
                            <label>
                                <p class="error-msg change-password-error">
                                    {this.props.language === "en" ?
                                        "Incorrect old password":
                                        "Gamalt lykilorð ekki rétt"
                                    }
                                </p>
                            </label>
                            <label>
                            <p class="error-msg password-mismatch">
                                {this.props.language === "en" ?
                                    "New password mismatch":
                                    "Nýtt lykilorð ekki slegið inn eins tvisvar"
                                }
                            </p>
                            </label>
                            <label>
                            <p class="success-msg">
                                {this.props.language === "en" ?
                                    "Password was changed":
                                    "Lykilorði var breytt"
                                }
                            </p>
                            </label>
                        </div>
                        <input 
                          className="login-btn"
                          type="submit"
                          value= { this.props.language === "en" ? "Confirm" : "Staðfesta"} />
                    </form>
                </div>
            </React.Fragment>
        )
    }
}

export default PasswordChange
