import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import Profile from "../components/profile"
import Login from "../components/login"
import PasswordChange from "../components/passwordChange"


const App = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/app/profile" component={Profile} />
      <Login path="/app/log_in" />
      <PrivateRoute path="app/nytt_lykilord" component={PasswordChange} />
    </Router>
  </Layout>
)
export default App
