import React from "react"
import propTypes from "prop-types"

import offersData from "../queries/staticQueries/offersData"

function offers({ group, language }) {
  //(group)
  //(offersData)
  const data = offersData()
  //(data)
  return (
    <div className="row">
      {data.allWordpressWpOffers.edges
        .filter(function(obj) {
          return obj.node.acf.offer_group.includes(group)
        })
        .map(({ node }) => (
          <div key={node.slug} className="col-md-4 col-xs-12">
            <div className="offer">
              <div className="discount">
                {" "}
                {node.acf.discount_percentage
                  ? node.acf.discount_percentage + "%"
                  : "2f1"}{" "}
              </div>
              <div className="location"> {node.acf.offer_location} </div>
              <h3 className="title">{node.title}</h3>
              <div className="offer-content">
                {language === "en"
                  ? node.acf.description_in_english
                  : node.acf.description_in_icelandic}
              </div>
              {node.acf.website ? 
                <div className="link">

                  <a href={node.acf.website}>
                    {node.acf.website}
                  </a>
                </div>: <React.Fragment></React.Fragment>
              }
            </div>
          </div>
        ))}
    </div>
  )
}

offers.propTypes = {
  edges: propTypes.array,
}

export default offers
